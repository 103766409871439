import $ from "jquery";

let map;

var googleMapMarkerTitle = 'Beck Reisen';
var googleMapMarkerLatitude = '48.25244157490997';
var googleMapMarkerLongitude = '12.843269621718656';
var googleMapLatitude = '448.25244157490997';
var googleMapLongitude = '12.843269621718656';

/*function initMap() {*/
window.initMap = function() {

    map = new google.maps.Map(document.getElementById("gmap"), {
        center: { lat: 48.25244157490997, lng: 12.843269621718656 }, //48.2552684,12.4165312
        zoom: 14,
        // How you would like to style the map.
        // This is where you would paste any style found on Snazzy Maps.
        styles: [
            {
                "featureType": "administrative",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#444444"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#f2f2f2"
                    }
                ]
            },
            {
                "featureType": "landscape.man_made",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#cdcdcd"
                    }
                ]
            },
            {
                "featureType": "landscape.natural.landcover",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "visibility": "off"
                    },
                    {
                        "color": "#af8383"
                    }
                ]
            },
            {
                "featureType": "landscape.natural.terrain",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#51d0b0"
                    },
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": 45
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#c5d1d6"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            }
        ]
    });

    var marker = new google.maps.Marker({
        position: new google.maps.LatLng(googleMapMarkerLatitude,googleMapMarkerLongitude),
        map: map,
        title: googleMapMarkerTitle,
    });


}

